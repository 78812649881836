import {Component, ElementRef} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import { faLinkedin, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleDown, faMugHot, faShapes } from '@fortawesome/free-solid-svg-icons'
import { rollInOnEnterAnimation, zoomInOnEnterAnimation } from 'angular-animations';

import { Person } from './person';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  animations: [
    zoomInOnEnterAnimation(),
    rollInOnEnterAnimation()
  ]
})
export class HomepageComponent {
  icon = {
    linkedin: faLinkedin,
    facebook: faFacebookSquare,
    arrows: faAngleDoubleDown,
    coffe: faMugHot,
    enough: faShapes
  };

  coding: Person = {
    fullname: 'Jarosław Nowicki',
    linkedin: '#',
    phone: '+ 48 699 999 123',
    email: 'jarek@novi-it.pl'
  };

  design: Person = {
    fullname: 'Natalia Suwara',
    linkedin: '#',
    phone: '+ 48 699 999 123',
    email: 'natalia@novi-it.pl'
  };

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('pl');

     // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
}
