<form novalidate class="contact-form">
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="fill" class="form-field">
                <mat-label class="form-field-label">Imię</mat-label>
                <input matInput />
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" class="form-field">
                <mat-label class="form-field-label">Email</mat-label>
                <input matInput />
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" class="form-field">
                <mat-label class="form-field-label">Wiadomość</mat-label>
                <textarea matInput></textarea>
            </mat-form-field>
        </div>
        <div class="col-12 text-right">
            <button mat-raised-button class="btn">Wyślij</button>
        </div>
    </div>
</form>