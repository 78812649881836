<main id="main" class="scroll-container" role="main">
    <section class="article-1">
        <div class="background-opacity"></div>
        <nav class="container navbar navbar-expand-md">
            <img class="d-md-none navbar-brand w-50" src="/assets/image/logo.png" />
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <i class="navbar-toggler-icon fas fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExampleDefault">
                <div class="navbar-nav justify-content-lg-between align-items-lg-center">
                    <div class="d-lg-flex navbar-left">
                        <div class="nav-item mr-lg-3">
                            <a class="nav-link" href="#">O nas <span class="sr-only">(current)</span></a>
                        </div>
                        <div class="nav-item dropdown ml-lg-3">
                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                Nasz realizacje
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">aplikacje</a>
                                <a class="dropdown-item" href="#">portale</a>
                                <a class="dropdown-item" href="#">strony www</a>
                            </div>
                        </div>
                    </div>
                    <div class="d-lg-block d-none nav-item nav-item-image">
                        <img src="/assets/image/logo.png" />
                    </div>
                    <div class="d-lg-flex navbar-right">
                        <div class="mr-lg-3 nav-item">
                            <a class="nav-link" href="#">Kontakt</a>
                        </div>

                        <div class="mx-lg-3 nav-item">
                            <a class="nav-link" href="#">900 099 900</a>
                        </div>
                        <div class="ml-lg-3 nav-item d-flex justify-content-between align-items-center">
                            <a class="nav-link" href="#"><fa-icon [icon]="icon.linkedin"></fa-icon></a>
                            <a class="nav-link" href="#"><fa-icon [icon]="icon.facebook"></fa-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="container">
            <div [@zoomInOnEnter]="{ value: '', params: { delay: 100, duration: 2500 } }" class="description" [innerHTML]="'homepage.description.artical-1' | translate"></div>
            <div [@zoomInOnEnter]="{ value: '', params: { delay: 200, duration: 1500 } }" class="lead" translate>homepage.lead</div>
            <div class="button-arrows">
                <div class="arrows"><fa-icon [icon]="icon.arrows"></fa-icon></div>
            </div>
        </div>
    </section>
    <section class="article-2">
        <div class="background-opacity"></div>
        <aside>
            <div class="aside-items d-flex align-items-center justify-content-around">
                <div class="aside-item we-create-software" [@rollInOnEnter]="{ value: '', params: { delay: 100, duration: 1000 } }" [innerHTML]="'homepage.aside.we-create-software' | translate"></div>
                <div class="aside-item u-need-coder" [@rollInOnEnter]="{ value: '', params: { delay: 600, duration: 1000 } }" [innerHTML]="'homepage.aside.u-need-coder' | translate"></div>
                <div class="aside-item here-i-am" [@rollInOnEnter]="{ value: '', params: { delay: 1400, duration: 1000 } }" [innerHTML]="'homepage.aside.here-i-am' | translate"></div>
            </div>
        </aside>
        <div class="container d-flex align-content-center flex-column">
            <div class="title">
                <span [innerHTML]="'homepage.title.artical-2.primary' | translate"></span>
                <span class="subtitle" [innerHTML]="'homepage.title.artical-2.secondary' | translate"></span>
            </div>
            <hr>
            <h1>Fika i lagom</h1>

            <div class="description description-coffe d-flex flex-row justify-content-start align-items-start align-self-start">
                <hr>
                <div class="icon"><fa-icon [icon]="icon.coffe"></fa-icon></div>
                <div class="text" [innerHTML]="'homepage.description.artical-2.coffe' | translate"></div>
            </div>

            <div class="description description-enough d-flex flex-row justify-content-start align-items-start align-self-start">
                <hr>
                <div class="icon"><fa-icon [icon]="icon.enough"></fa-icon></div>
                <div class="text"[innerHTML]="'homepage.description.artical-2.enough' | translate"></div>
            </div>
        </div>
    </section>
    <section class="article-3">
        <div class="background-opacity"></div>
        <aside>
            <div class="aside-items d-flex align-items-center justify-content-between">
                <div class="aside-item our-projects">
                    <span [innerHTML]="'homepage.aside.our-projects' | translate"></span>
                    <hr />
                </div>
                <div class="aside-item projects-info" [innerHTML]="'homepage.aside.projects-info' | translate"></div>
            </div>
        </aside>
        <div class="container">
            <div class="we-trust d-flex align-items-center" [innerHTML]="'homepage.header.artical-3' | translate"></div>
            <div class="projects d-flex justify-content-around align-items-start">
                <div class="project apps d-flex flex-column">
                    <img src="/assets/image/apps.png" />
                    <h3 [innerHTML]="'homepage.title.artical-3.apps' | translate"></h3>
                    <h6 [innerHTML]="'homepage.description.artical-3.apps' | translate"></h6>
                </div>
                <div class="project portals d-flex flex-column">
                    <img src="/assets/image/portals.png" />
                    <h3 [innerHTML]="'homepage.title.artical-3.portals' | translate"></h3>
                    <h6 [innerHTML]="'homepage.description.artical-3.portals' | translate"></h6>
                </div>
                <div class="project landings d-flex flex-column">
                    <img src="/assets/image/landings.png" />
                    <h3 [innerHTML]="'homepage.title.artical-3.landings' | translate"></h3>
                    <h6 [innerHTML]="'homepage.description.artical-3.landings' | translate"></h6>
                </div>
            </div>
        </div>
    </section>
    <section class="article-4">
        <div class="background-opacity"></div>
        <aside>
            <div class="aside-items">
            </div>
        </aside>
        <div class="title">
            <h1 [innerHTML]="'homepage.header.artical-4' | translate"></h1>
        </div>
        <div class="container">
            <div class="row h-100">
                <div class="col-3 d-flex align-items-start flex-column">
                    <div class="contact mb-auto">
                        <span [innerHTML]="'homepage.contact.title' | translate"></span>
                        <hr />
                    </div>
                    <div class="header">
                        <div class="circle"></div>
                        <span [innerHTML]="'homepage.contact.do-you-idea' | translate"></span>
                    </div>
                    <app-person [person]="coding" [title]="'homepage.contact.person.coding' | translate"></app-person>
                </div>
                <div class="col-4 d-flex align-items-start flex-column">
                    <div class="write-to-us mb-auto">
                        <h1 [innerHTML]="'homepage.title.artical-4' | translate"></h1>
                    </div>
                    <div class="header" [innerHTML]="'homepage.contact.write-to-us' | translate"></div>
                    <app-person [person]="design" [title]="'homepage.contact.person.design' | translate"></app-person>
                </div>
                <div class="col-5">
                    <app-contact></app-contact>
                </div>
            </div>
        </div>
    </section>
</main>
