import { Component, Input, OnInit } from '@angular/core';
import { Person } from '../person';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @Input() person: Person;
  @Input() title: string;

  linkedin = faLinkedin;
  mailto = '';

  constructor() { }

  ngOnInit(): void {
    this.mailto = 'mailto:' + this.person.email;
  }
}
